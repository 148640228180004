@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

$TT-red: #d22929;

#cardContainer {
  text-align: -webkit-center;
  text-align: -moz-center;
}

.card {
  color: #fff;
  position: relative;
  width: 25em;
  height: 25em;

  @media screen and (min-width: 300px) and (max-width: 830px) {
    width: 20em;
    height: 23em;
    margin-top: 1em;
  }

  img {
    opacity: 1;
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: 0.5s ease;
    backface-visibility: hidden;
  }
  .hidden-text {
    transition: 0.5s ease;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    padding: 2em;
    overflow: hidden;
    &:hover {
      opacity: 1;
    }
  }
  h3 {
    color: $TT-red;
    margin: 0;
  }
  a h4 {
    color: white;
    font-size: 1.5em;
    margin: 0;
  }
  h5 {
    font-size: 0.9em;
    margin: 0;
  }
  P {
    margin-top: 1em;
  }
}

.hidden-text {
  float: left !important;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: 0.5s ease;
  background-color: rgba(000, 000, 000, 0.67);
  p {
    font-family: "Roboto";
  }
}

#title {
  color: black;
  font-size: 24px;
  margin-top: 1em;
  font-weight: 700;
  width: 70%;
  text-align: -webkit-center;
  text-align: -moz-center;

  &:hover {
    text-decoration: none !important;
  }

  @media (min-width: 300px) and (max-width: 800px) {
    font-size: 20px;
    width: 90%;
  }
}

#date {
  font-size: 16px;
  width: 90%;
  text-align: -webkit-center;
  text-align: -moz-center;
  font-weight: 500;
  margin-top: -0.5em;
}
