$TT-red: #d22929;

.nav-parent {
  border-bottom: 0.1px solid rgba(0, 0, 0, 0.096);
}

#traversing {
  font-size: 24px;
  transition: color 0.5s;
  margin-top: 0.2em;
  margin-left: 1em;
  font-weight: 600;
  @media screen and (min-width: 300px) and (max-device-width: 830px) {
    font-size: 20px;
  }
  &:hover {
    color: $TT-red !important;
  }
  &::first-letter {
    color: $TT-red;
  }
}

#tradition {
  font-size: 24px;
  margin-top: -46px;
  margin-left: 70.5px;
  font-weight: 600;
  transition: color 0.5s;
  @media screen and (min-width: 300px) and (max-device-width: 830px) {
    font-size: 20px;
  }
  &:hover {
    color: $TT-red !important;
  }
  &::first-letter {
    color: $TT-red;
  }
}

#nav-links {
  font-size: 18px;
  gap: 15px;
  font-weight: 600;
  @media screen and (min-width: 300px) and (max-device-width: 830px) {
    gap: 0;
    align-items: center;
  }
}

.navbar-light .navbar-nav .nav-link {
  color: #000;
  transition: color 0.5s;
  &:hover {
    color: $TT-red !important;
  }
  &::first-letter {
    color: $TT-red;
  }
}

#social-icons {
  display: flex;
  flex-direction: row;
  font-size: 19px;
  @media screen and (min-width: 300px) and (max-device-width: 830px) {
    gap: 0;
    place-content: center;
  }
  a {
    color: black;
    padding: 8px;
    transition: color 0.5s;
    &:hover {
      color: $TT-red;
    }
  }
}

#language {
  display: flex;
  align-items: baseline;
  margin-top: 0.27em;
  margin-right: 3em;
  @media screen and (min-width: 300px) and (max-device-width: 830px) {
    grid-gap: 0;
    gap: 0;
    place-content: center;
    margin-left: 3em;
    padding: 0.5em;
    border-top: 0.1px solid rgba(0, 0, 0, 0.096);
  }
  p {
    padding: 0;
    margin: 0;
  }
  a {
    color: black;
    padding: 1px;
    font-weight: 600;
    font-size: 14px;
    transition: color 0.5s;
    &:hover {
      color: $TT-red;
    }
  }
}
.search-bar {
  background-color: #f8f9fa !important;
  transition: all 0.5s ease;
}

.gsc-control-cse {
  background-color: #f8f9fa !important;
}
