$TT-red: #d22929;

.aboutUs {
  min-height: calc(100vh - 310px);
}
.about {
  margin-left: 20%;
  margin-right: 20%;
  margin-top: 3em;
  margin-bottom: 2em;
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.288);
  h1 {
    color: $TT-red;
    border-bottom: 0.5px solid rgba(0, 0, 0, 0.288);
    width:fit-content;
    @media screen and (min-width: 300px) and (max-device-width: 830px) {
        font-size: 24px;
    }
  }
  p {
    font-family: "Lora", serif;
  }
}
