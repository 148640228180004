.footer {
  padding: 1em;
  position: relative;
  margin-top: 1em;
  background-color: #323639;
  width: 100%;
  color: #fff;
  padding-bottom: 10px;
  padding-top: 10px;
  h3 {
    border-bottom-style: double;
    width:10em;
  }
  a {
    padding: 0;
    color: #fff;
    transition: color 0.5s;
    &:hover {
      color: #d22929;
    }
  }
  .row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 10em;
    padding-top: 2em;
    padding-bottom: 1em;
    @media screen and (min-width: 300px) and (max-device-width: 830px) {
      gap: 5em;
      margin-left: -9px !important;
    }
  }
  #social-icons {
    display: flex;
    place-content: center;
    flex-direction: row;
    gap: 20px;
    margin-top: -2em;
    margin-left: 3em;
  }
}

.column {
  text-align-last: center;
  // margin-left: 10%;
  display: flex;
  flex-direction: column;
}

.news-letter {
  display: block;
  text-align-last: start;
  input {
    width: 100%;
    height: fit-content;
    font-size: 14px;
    font-weight: 700;
  }
}

.btn-action {
  font-size: 14px;
  font-weight: 700;
  color: white;
  display: block;
  margin-top: 10px;
  padding: 5px;
  background-color: #d22929;
  border: none;
}
