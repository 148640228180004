$TT-red: #d22929;

.article-container {
  position: relative;
  width: 33%;
  @media screen and (min-width: 300px) and (max-device-width: 830px) {
    width: 100%;
  }
}

.link-img {
  position: relative;
  width: 100%;
  height: 25em;
  margin-left: 5px;
}

.link-title {
  position: relative;
  font-size: 28px;
  text-align: -webkit-center;
  text-align:-moz-center;
  margin-top: 30px;
  color: black;
  font-weight: 500;
  transition: all 0.5s;
  line-height: 28px;
  &:hover {
    color: $TT-red !important;
  }
}

.hidden-container {
  display: flex;
  width: 100%;
  background-color: #d22929;
  color: white;
  margin-left: 5px;
  position: absolute;
  z-index: 12;
  bottom: 0px;
  height: 1.5em;
  .article-date {
    position: absolute;
    right: 15px;
  }
  .link-author {
    position: absolute;
    left: 15px;
  }
}

.card-holder {
  display: block;
  position: relative;
}

.loading-container {
  text-align: -webkit-center;
  text-align:-moz-center;
  margin-top: 20em;
  margin-bottom: 20em;
  @media screen and (min-width: 300px) and (max-device-width: 830px) {
    margin-top: 15em;
    margin-bottom: 15em;
  }
  .spinner {
    height: 20em;
    width: 20em;
    @media screen and (min-width: 300px) and (max-device-width: 830px) {
      height: 10em;
      width: 10em;
    }
  }
}

.link-description {
  margin-top: 1em;
  text-align: -webkit-center;
  text-align:-moz-center;
  font-family: "Lora", serif;
}
