.pubs {
  background-color: #323639;
  color: #fff;
  padding: 1rem;
  @media screen and (min-width: 300px) and (max-device-width: 830px) {
    font-size: 1.5rem;
  }
}

.home-container {
  width: 96%;
  text-align: -webkit-center;
  text-align: moz-center;
  margin-left: -1em;
  .row {
    gap: 4em;
    margin-top: 1em;
    margin-bottom: 1em;
    margin-left: 5em;
    width: 80% !important;
    @media screen and (min-width: 300px) and (max-device-width: 830px) {
      row-gap: 1em !important;
      margin-top: 1em !important;
      margin-bottom: 1em !important;
      column-gap: 13em;
      h2 {
        font-size: 1.3rem;
        margin: 0.5em;
        width: 100%;
      }
    }
    .col {
      flex-basis: 0;
      flex-grow: 1;
      width: 100%;
      @media screen and (min-width: 300px) and (max-device-width: 830px) {
        margin-left: 20px;
      }
      h6 {
        font-size: 1rem;
        margin-top: -0.7em;
        @media screen and (min-width: 300px) and (max-device-width: 830px) {
          font-size: 0.8rem;
          margin-top: -1em;
        }
      }
      h2 {
        font-size: 1.5rem;
        font-weight: 700;
        @media screen and (min-width: 300px) and (max-device-width: 830px) {
          font-size: 1.2rem;
        }
      }
    }
  }
}
.hover-container {
  opacity: 1;
  margin-left: 4.2em;
  margin-right: 2em;
  width: 93% !important;
  @media screen and (min-width: 300px) and (max-device-width: 830px) {
    width: 80% !important;
    margin-left: 3.5em !important;
  }
  .row {
    gap: 0em !important;
    margin-left: 0.5em;
    @media screen and (min-width: 300px) and (max-device-width: 830px) {
      margin-left: -37px !important;
      .col {
        @media screen and (min-width: 300px) and (max-device-width: 830px) {
          margin-left: 20px;
        }
      }
    }
  }
}

.verse-container {
  gap: 6em;
  margin-left: 4.39em;
  $TT-red: #d22929;

  .card {
    @media screen and (min-width: 300px) and (max-device-width: 830px) {
      display: none;
    }
  }

  .article-container {
    position: relative;
    width: 15%;
  }

  .link-img {
    position: relative;
    width: 21em;
    height: 17em;
    margin-left: 5px;
    @media screen and (min-width: 300px) and (max-device-width: 830px) {
      width: 20em;
      height: 13em;
    }
  }

  .link-title {
    position: relative;
    font-size: 31px;
    width: 188%;
    line-height: 25px;
    margin-top: 10px;
    color: black;
    font-weight: 500;
    transition: all 0.5s;
    @media screen and (min-width: 300px) and (max-device-width: 830px) {
      width: max-content;
    }
    &:hover {
      color: $TT-red !important;
    }
  }

  .link-author {
    position: relative;
    margin-top: -8px;
    margin-left: 17px;
    width: max-content;
    font-size: 18px;
    font-weight: 500;
  }

  .link-description {
    position: relative;
    position: relative;
    margin-left: 16px;
    font-size: 12px;
    width: 160%;
    @media screen and (min-width: 300px) and (max-width: 830px) {
      width: 17em;
    }
  }
  .row {
    gap: 11em;
    width: 88% !important;
    row-gap: 3em;
    @media screen and (min-width: 300px) and (max-device-width: 830px) {
      grid-gap: 11em;
      gap: 11em;
      width: 10% !important;
      grid-row-gap: 3em;
      margin-left: -6em !important;
      row-gap: 2em;
    }
    .col {
      margin-left: 4px;
      @media screen and (min-width: 300px) and (max-device-width: 830px) {
        margin-left: 20px;
      }
    }
    .card {
      color: #fff;
      position: relative;
      width: 100%;
      height: 27em;
      margin-left: 5em;
      img {
        width: 106%;
      }
      .hidden-text {
        width: 106%;
        margin-left: 3em;
      }
    }
  }
}

.video-container {
  position: relative;
  overflow: hidden;
  height: 22em;
  width: 40em;
  margin-bottom: 1em;
  @media screen and (min-width: 300px) and (max-device-width: 830px) {
    height: 16em;
    width: 20em;
  }
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }
}

.verse-prose-container {
  margin-left: 24px;
}
