.verse-content-container {
  border-width: thick;
  border: 0.4px solid;
  padding: 0 !important;
  margin: 0;
  @media (min-width: 300px) and (max-width: 800px) {
    height: 7.6em !important;
  }
  .row {
    margin: 0;
    gap: 0;
    padding: 0;
    @media (min-width: 300px) and (max-width: 800px) {
      height: 8em !important;
    }
    h4 {
      border-bottom: thin solid black;
      max-inline-size: fit-content;
      @media (min-width: 300px) and (max-width: 800px) {
        font-size: 1.2901em !important;
      }
    }
    .img-container {
      max-width: 23em;
      padding: 0;
      overflow: hidden !important;
      img {
        @media (min-width: 300px) and (max-width: 800px) {
          position: absolute;
          left: 9px;
          top: -14px;
        }
      }
      @media (min-width: 300px) and (max-width: 800px) {
        height: 10.84em !important;
      }
    }
    .col {
      height: 15em;
      overflow-y: scroll;
      @media (min-width: 666px) and (max-width: 1024px) {
        height: 10em;
      }
      @media (min-width: 300px) and (max-width: 800px) {
        height: 8.3em !important;
      }
      @media (min-width: 300px) and (max-width: 800px) {
        font-size: 0.9em !important;
      }
    }
  }
}
