@import url("https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Cinzel:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

$TT-red: #d22929;

.carousel {
  position: relative;
}
.bg-image {
  background: url(../assets/idk2.jpg);
  opacity: 0.9;
  background-size: cover;
  background-position: center center;
  width: 100%;
  height: 45.5vh;
  display: block;
  flex: 1;
}
a {
  .carouCard {
    color: #fff;
    position: relative;
    margin-left: 0px !important;
    padding-left: 0px !important;
    width: 100% !important;
    background-color: rgba(000, 000, 000, 0.57);
    height: 100%;
    padding: 9em;
    padding-left: 9em !important;
    padding-top: 2em;
    text-align: -webkit-center;
    text-align: -moz-center;
    @media screen and (min-width: 300px) and (max-device-width: 830px) {
      padding: 4em;
      padding-left: 4em !important;
      padding-top: 2em;
    }
    .row {
      padding-left: 3rem;
      opacity: 1;
      margin-left: 10rem;
      gap: 0em !important;
    }
    .text {
      font-family: "Lora", serif;
      opacity: 1;
      padding-right: 53rem;
    }
    .mybtn {
      font-family: "Cinzel";
      position: absolute;
      background-color: $TT-red !important;
      border-color: $TT-red !important;
      bottom: 4em;
      right: 10em;
      display: block;
      float: right;
      font-size: 24px;
      @media screen and (min-width: 300px) and (max-device-width: 830px) {
        font-size: 0.9em;
        position: relative;
        margin-top: 5rem;
        left: 0;
      }
    }
    h3 {
      font-family: "Cinzel";
      color: white;
      margin: 0;
      font-size: 3rem;
      position: sticky;
      top: 35%;
      border-bottom: ridge;
      width: 90%;
      &:hover {
        color: $TT-red;
      }
      @media screen and (min-width: 300px) and (max-device-width: 830px) {
        font-size: 1rem;
        top: 30%;
      }
    }
    h4 {
      font-family: "Cinzel";
      color: white;
      font-size: 1.7rem;
      position: sticky;
      top: 56%;
      @media screen and (min-width: 830px) and (max-device-width: 930px) {
        margin-top: 6em;
      }
      @media screen and (min-width: 930px) and (max-device-width: 1260px) {
        margin-top: 3em;
      }
      @media screen and (min-width: 1260px) and (max-device-width: 1550px) {
        margin-top: 4em;
      }
      @media screen and (min-width: 300px) and (max-device-width: 830px) {
        font-size: 0.8rem;
        top: 45%;
      }
    }
    h5 {
      font-family: "Cinzel";
      font-size: 0.9em;
      margin: 0;
    }
    p {
      font-size: 24px;
      @media screen and (min-width: 300px) and (max-device-width: 830px) {
        font-size: 12px;
      }
    }
  }
}
