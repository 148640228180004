@import "~bootstrap/scss/bootstrap";

$TT-red: #d22929;

* {
  margin: 0;
  box-sizing: border-box;
  padding-left: 0;
}
body {
  font-family: "Cinzel", serif;
  @import url("https://fonts.googleapis.com/css2?family=Cinzel:wght@400;500;600;700;800;900&display=swap");
  overflow-x: hidden;
  width: 100%;
}

.row {
  width: 100% !important;
}

a {
  h4 {
    &:hover {
      color: $TT-red !important;
      text-decoration: none !important;
    }
  }
}

a {
  &:hover {
    color: $TT-red !important;
    text-decoration: none !important;
  }
}

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: $TT-red;
  border-color: $TT-red;
}

.page-link {
  color: black;
}

.empty-space {
  margin-top: 20em;
}
.spinner {
  height: 20em;
  width: 20em;
  @media screen and (min-width: 300px) and (max-device-width: 830px) {
    height: 10em;
    width: 10em;
  }
}
