.pagination-container {
  .row {
    gap: 5em;
    padding-top: 3em;
    padding-bottom: 3em;
    @media screen and (min-width: 300px) and (max-device-width: 830px) {
      padding-left: 0;
    }
  }
  .pagination {
    place-content: center;
  }
}
