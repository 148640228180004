.article-header {
  overflow-x: hidden;
  background-color: black;
  background-image: url("../assets/Traversing\ Tradition.png");
  background-size: cover;
  width: 100%;
  height: 38em;
  text-align: -webkit-center;
  text-align: -moz-center;
  .cover {
    background-color: rgba(000, 000, 000, 0.57);
    width: 100%;
    height: 100%;
    padding-right: 25%;
    padding-left: 25%;
  }
  h1 {
    color: white;
    padding-top: 1em;
    font-size: 2.5em;
  }
  h4 {
    color: white;
    size: 1.5em;
    border-top: 1px solid white;
    padding-top: 1em;
  }
  #overview {
    color: white;
    padding-top: 1.3em;
    font-size: 1.2rem;
    text-align: -webkit-center !important;
    text-align: -moz-center !important;
    font-family: "Lora", serif;
  }
  #time {
    color: white;
    padding-top: 1rem;
    font-size: 1rem;
    text-align: -webkit-center !important;
    text-align: -moz-center !important;
    font-family: "Lora", serif;
  }

  //Scroll action
  #section03 {
    top: 54em;
    position: absolute;
    left: 50%;
    right: 50%;
  }
  .demo a {
    position: absolute;
    bottom: 20px;
    left: 50%;
    z-index: 2;
    display: inline-block;
    -webkit-transform: translate(0, -50%);
    -moz-transform: translate(0, -50%);
    transform: translate(0, -50%);
    color: #fff;
    font: normal 400 20px/1 "Josefin Sans", sans-serif;
    letter-spacing: 0.1em;
    text-decoration: none;
    transition: opacity 0.3s;
  }
  .demo a:hover {
    opacity: 0.5;
  }
  #section03 a {
    padding-top: 60px;
  }
  #section03 a span {
    position: absolute;
    top: -7em;
    left: 50%;
    width: 46px;
    height: 46px;
    margin-left: -23px;
    border: 1px solid #fff;
    border-radius: 100%;
    box-sizing: border-box;
  }
  #section03 a span::after {
    position: absolute;
    top: 50%;
    left: 50%;
    content: "";
    width: 16px;
    height: 16px;
    margin: -12px 0 0 -8px;
    border-left: 1px solid #fff;
    border-bottom: 1px solid #fff;
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    transform: rotate(-45deg);
    box-sizing: border-box;
  }
  #section03 a span::before {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    content: "";
    width: 44px;
    height: 44px;
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.1);
    border-radius: 100%;
    opacity: 0;
    -webkit-animation: sdb03 3s infinite;
    -moz-animation: sdb03 3s infinite;
    animation: sdb03 3s infinite;
    box-sizing: border-box;
  }
  @-webkit-keyframes sdb03 {
    0% {
      opacity: 0;
    }
    30% {
      opacity: 1;
    }
    60% {
      box-shadow: 0 0 0 60px rgba(255, 255, 255, 0.1);
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  @keyframes sdb03 {
    0% {
      opacity: 0;
    }
    30% {
      opacity: 1;
    }
    60% {
      box-shadow: 0 0 0 60px rgba(255, 255, 255, 0.1);
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }

  @media screen and (min-width: 300px) and (max-device-width: 830px) {
    height: 27em;
    width: 100%;

    .cover {
      padding-left: 10%;
      padding-right: 10%;
    }
    h1 {
      font-size: 1.5em;
    }
    h4 {
      font-size: 1.1em;
    }
    #overview {
      font-size: 0.7em;
      text-align: -webkit-left;
      text-align: -moz-left;
    }
    #time {
      font-size: 0.8rem;
    }
    #section03 {
      display: none;
      top: 47em;
      position: absolute;
      left: 50%;
      right: 50%;
    }
  }
}

.article-body {
  font-family: "Lora", serif;
  margin-top: 2em;
  margin-bottom: 4em;
  font-size: 18px;
  margin-left: 15em;
  margin-right: 15em;
  .first-letter {
    display: block;
    float: left;
    text-align: center;
    margin: 5px 15px 15px 0;
    background: #48363d;
    color: #fff;
    line-height: 125px;
    font-size: 125px;
    width: 125px;
    height: 125px;
  }
  p {
    margin-bottom: 2.5em;
  }
  @media screen and (min-width: 300px) and (max-device-width: 830px) {
    margin-top: 4em;
    margin-bottom: 4em;
    font-size: 18px;
    margin-left: 2rem;
    margin-right: 2rem;
    display: flex;
    flex-direction: column;
    white-space: normal;
    line-break: strict;
    overflow-wrap: break-word;
    ::nth-letter(1) {
      display: block;
      float: left;
      text-align: center;
      margin: 5px 15px 15px 0;
      background: #48363d;
      color: #fff;
      line-height: 90px;
      font-size: 80px;
      width: 90px;
      height: 90px;
    }
    p {
      margin-bottom: 2em;
    }
  }
}
.article-info {
  width: 100%;
  border-top: 0.5px solid rgba(0, 0, 0, 0.288);
  text-align: -webkit-center;
  text-align: -moz-center;
  .article-author {
    // border: 0.5px solid rgba(0, 0, 0, 0.692);
    img {
      border: 3px solid rgba(0, 0, 0, 0.473);
      border-radius: 100%;
      width: 4em;
      height: 4em;
      margin: 1em;
      margin-left: 1.4rem;
    }
    .author-name {
      font-size: 18px;
      color: #000;
      font-weight: 700;
    }
    .article-date {
      font-size: 18px;
      color: #000;
      font-weight: 700;
    }
  }
  .tags {
    height: 6em;
    width: 32em;
    display: flex;
    grid-gap: 0em;
    gap: 0em;
    grid-column-gap: 1px;
    column-gap: 17px;
    flex-wrap: wrap;
    margin-left: -16em;
    margin-top: 2em;
    list-style: circle;
    font-size: 14px;
    font-weight: 500;
    @media screen and (min-width: 300px) and (max-device-width: 830px) {
      margin-top: -4em;
      height: 11em;
      margin-left: 0;
      width: 100%;
      gap: 10px;
      margin-left: 3em;
    }
  }
  .share {
    height: 6em;
    width: 15em;
    display: flex;
    column-gap: -1px;
    flex-wrap: wrap;
    margin-left: -16em;
    margin-top: 2em;
    .nav-link {
      display: block;
      padding: 0.5rem 0.8em;
      height: fit-content;
    }
    @media screen and (min-width: 300px) and (max-device-width: 830px) {
      height: 6em;
      width: 8em;
      margin-left: 1em;
      margin-top: -5em;
    }
  }
  .related {
    margin-left: 30em;
    margin-right: 30em;
    border-top: 0.5px solid rgba(0, 0, 0, 0.288);
    text-align: -webkit-center;
    text-align: -moz-center;
  }
  @media screen and (min-width: 300px) and (max-device-width: 830px) {
    margin-left: 10em;
    margin-right: 10em;
    border-top: 0.5px solid rgba(0, 0, 0, 0.288);
    text-align: -webkit-center;
    text-align: -moz-center;
  }
}

.related {
  width: 100%;
  border-top: 0.5px solid rgba(0, 0, 0, 0.288);
  .related-title {
    font-size: 20px;
    margin: 1em;
    font-weight: 700;
  }
  .row {
    gap: 4em;
    .row {
      @media screen and (min-width: 300px) and (max-device-width: 830px) {
        padding-left: 0;
      }
    }
  }
}
.row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 20em;
  padding-top: 2em;
  padding-bottom: 1em;
  @media screen and (min-width: 300px) and (max-device-width: 830px) {
    gap: 5em;
    margin-left: -9px !important;
  }
}

.loading-container {
  text-align: -webkit-center;
  text-align: -moz-center;
  margin-top: 20em;
  margin-bottom: 20em;
  @media screen and (min-width: 300px) and (max-device-width: 830px) {
    margin-top: 15em;
    margin-bottom: 15em;
  }
  .spinner {
    height: 20em;
    width: 20em;
    @media screen and (min-width: 300px) and (max-device-width: 830px) {
      height: 10em;
      width: 10em;
    }
  }
}
